/**
 * @file App config
 * @module config.app
 */

export const DEFAULT_DELAY = 468
export const IMAGE_SHARE_LONG_ARTICLE_THRESHOLD = 6688
export const RENDER_LONG_ARTICLE_THRESHOLD = 16688

export const META = Object.freeze({
  title: 'somecore.cn',
  zh_sub_title: '因为曾经感动，所以难以忘怀',
  en_sub_title: `yanwei's digital garden`,
  zh_description: '理虽顿悟，事须渐修；崇德尚能，知行合一',
  en_description: 'Practice makes perfect',
  url: 'http://somecore.cn',
  domain: 'somecore.cn',
  author: 'yanwei',
  primary: '#0088f5'
})

export const GEO_INFO = Object.freeze({
  zh_title: '北京时间',
  en_title: 'UTC +07:00 ~ +09:00',
  coordinates: [103.830391822121, 1.340863]
})

export const MAPBOX_CONFIG = Object.freeze({
  // readonly token
  TOKEN: 'pk.eyJ1Ijoic3VybW9uIiwiYSI6ImNsNDE4YmkzNjB2Z2wzY3F5dWg2M2tqeWIifQ.JhgYGFI4zsuNiX9dH-pBDg',
  STYLE_LIGHT: 'mapbox://styles/surmon/cl41fktzn000f14pet94oo1u4',
  STYLE_DARK: 'mapbox://styles/surmon/cl41gy1qo000l15ry20j5ae0k',
  ZOOM: 12.4374,
  CENTER: [103.830391822121, 1.348463]
})

export const IDENTITIES = Object.freeze({
  GOOGLE_ANALYTICS_MEASUREMENT_ID: 'G-VE8SYN8MPN',
  GOOGLE_ADSENSE_CLIENT_ID: 'ca-pub-2243200252172865',
  SENTRY_PUBLIC_DSN: '', // 置为空，表示先关闭监控
  YOUTUBE_CHANNEL_ID: 'UCoL-j6T28PLSJ2U6ZdONS0w',
  YOUTUBE_CHANNEL_SHORT_ID: '99622895',
  MUSIC_163_BGM_ALBUM_ID: '8279986950',
  DOUBAN_USER_ID: '135669449',
  GITHUB_USER_NAME: 'yuanjingteam',
  // TWITTER_USER_NAME: 'yanwei',
  INSTAGRAM_USERNAME: 'yanwei',
  BTC_ADDRESS: 'bc1qhpdu03tnexkj4xsm3lqzyjdddz6z0rj2n7fsze',
  ETH_ADDRESS: '0xaD556974D449126efdeF23f4FF581861C301cB77'
})

export const VALUABLE_LINKS = Object.freeze({
  RSS: '/rss.xml',
  SITE_MAP: '/sitemap.xml',
  UPTIME_STATUS: 'https://redirect.somecore.cn/status',
  BEIAN: 'https://beian.miit.gov.cn',
  NPM_HOMEPAGE: 'https://www.npmjs.com/org/ort-fe',
  PAYPAL: 'https://paypal.me',
  // GITHUB_SPONSORS: `https://github.com/sponsors/${IDENTITIES.GITHUB_USER_NAME}`,
  GITHUB: `https://github.com/${IDENTITIES.GITHUB_USER_NAME}`,
  GITHUB_SURMON_ME: 'https://github.com/yuanjingteam',
  GITHUB_NODEPRESS: 'https://github.com/yuanjingteam',
  GITHUB_SURMON_ME_NATIVE: 'https://github.com/yuanjingteam',
  GITHUB_BLOG_STAR_LIST: 'https://github.com/nodejs/node',
  MARKDOWN: 'https://daringfireball.net/projects/markdown/',
  GOOGLE_MY_MAP: `https://www.google.com/maps/d/embed?mid=1sRx6t0Yj1TutbwORCvjwTMgr70r62Z6w&z=3`,
  GOOGLE_MY_MAP_KML: `https://www.google.com/maps/d/u/0/kml?forcekml=1&mid=1sRx6t0Yj1TutbwORCvjwTMgr70r62Z6w`,
  DISCORD_GROUP: 'https://redirect.somecore.cn/discord-server',
  TELEGRAM_GROUP: 'https://redirect.somecore.cn/telegram-group',
  MUSIC_163: `https://music.163.com/#/playlist?id=${IDENTITIES.MUSIC_163_BGM_ALBUM_ID}`,
  YOUTUBE_CHANNEL: `https://space.bilibili.com/${IDENTITIES.YOUTUBE_CHANNEL_SHORT_ID}/channel/series/`,
  TELEGRAM: 'https://t.me',
  OPENSEA: 'https://opensea.io',
  DOUBAN: 'https://www.douban.com/people/yanweibean',
  DOUBAN_MOVIE: `https://movie.douban.com/people/yanweibean/collect`,
  LINKEDIN: 'https://www.linkedin.com/in',
  INSTAGRAM: `https://www.instagram.com/${IDENTITIES.INSTAGRAM_USERNAME}`,
  TWITTER: `https://twitter.com`
})
