/**
 * @file Tunnel constant
 * @module constant.tunnel
 */

export enum TunnelModule {
  WebFont = 'webfont',
  MyGoogleMap = 'my_google_map',
  // TwitterAggregate = 'twitter_aggregate',
  YouTubePlaylist = 'youtube_playlist',
  YouTubeVideoList = 'youtube_video_list',
  InstagramProfile = 'instagram_profile',
  InstagramMedias = 'instagram_medias',
  InstagramMediaChildren = 'instagram_media_children',
  InstagramCalendar = 'instagram_calendar',
  BingWallpaper = 'bing_wallpaper',
  GitHubSponsors = 'github_sponsors',
  GitHubContributions = 'github_contributions',
  NetEaseMusic = '8279986950',
  DoubanMovies = 'douban_movies',
  OpenSourceGitHubStatistic = 'open_source_github_statistic',
  OpenSourceNPMStatistic = 'open_source_npm_statistic'
}
