/**
 * @file Clipboard util
 * @module util.clipboard
 */

import { enableCopyrighter, disableCopyrighter } from '/@/effects/copyright'

export const read = () => navigator.clipboard.readText()
export const copy = async (text: string) => {
  disableCopyrighter()
  try {
    // https://www.w3.org/TR/clipboard-apis/#async-clipboard-api
    // 提示: only https site
    // await window.navigator.clipboard?.writeText(text)

    const clipboard = window.navigator.clipboard || {
      writeText: (text) => {
        const copyInput = document.createElement('textarea')
        copyInput.value = text
        document.body.appendChild(copyInput)
        copyInput.select()
        document.execCommand('copy')
        document.body.removeChild(copyInput)
      }
    }
    await clipboard.writeText(text)
  } catch (err) {
    console.error('复制文本失败: ', err)
  } finally {
    enableCopyrighter()
  }
}

export default { copy, read }
